@use "sass:map";
@import "styles/variables.scss";

.searching {
  max-width: 40rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  /* input 기본 스타일 초기화 */
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    all: unset;
    box-sizing: border-box;
  }

  &-idiom {
    max-height: 40rem;
  }

  &-grammar {
    border: 1px solid orange;
  }

  &-input {
    border: 1px solid green;
    width: 100%;
    border-radius: 5px;
    border: 1px solid map.get($--colors, "gray_3");
    background-color: map.get($--colors, "gray_1");
  
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    input {
      padding-top: 2px;
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;
      height: 2.75rem;
      line-height: 2.75rem;
  
      &::placeholder {
        color: #777;
        font-size: 15px;
      }
    }
  
    .search_icon_wrapper {
      margin-right: 4px;
      width: 2.7rem;
      height: 2.2rem;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      &:hover {
        background-color: #eaeaea;
      }
    }
  }

  &-buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
}