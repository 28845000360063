.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: fit-content;
}
.select__label {
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9rem;
  font-weight: 600;
  padding-bottom: 0.4rem;
}
.select__outer_box {
  padding: 0 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
  
}
.select__outer_box:hover {
  border: 2px solid rgba(0, 0, 0, 0.4);
}
.select__inactive {
  border: 2px solid rgba(0, 0, 0, 0.1);
}
.select__active {
  border: 2px solid #2f75f1;
  /* #3188fe */
}
.select__inner_box {
  padding-right: 0.5rem;
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  overflow: auto;
}
.select__arrow {
  width: 1.1rem;
}
.select__arrow_open {}
.select__background_close {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.select__option_box {
  padding-top: 0.5rem;
  position: absolute;
  width: 100%;
  min-width: 10rem;
  max-height: 15rem;
  min-height: fit-content;
  overflow-y: auto;
  z-index: 1100;
  cursor: pointer;
  border-radius: 0.3rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.select__option {
  padding: 0.7rem 1rem;
  width: 100%;
  font-size: 0.9rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.select__option:hover {
  background-color: rgba(0, 0, 0, 0.05);
}