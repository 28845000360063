@use "sass:map";
@import "styles/variables.scss";

.tr {
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: map.get($--colors, "gray_1");
  }
}
.tr_even {
  background-color: map.get($--colors, "gray_1");
  
  &:hover {
    background-color: map.get($--colors, "gray_1");
  }
}
.tr_active {
  background-color: map.get($--colors, "blue-200");
  
  &:hover {
    background-color: map.get($--colors, "blue-200");
  }
}
.tr_disabled {
  cursor: default;

  &:hover {
    background-color: transparent;
  }
}

.tr_is_selected {
  background-color: map.get($--colors, "blue-300");

  &:hover {
    background-color: map.get($--colors, "blue-300");
  }
}

.td {
  padding: 0.4rem;
  font-size: 13px;
  word-break: break-all;
  width: 100%;

  &-selected {
    background-color: map.get($--colors, "gray_2");
  }
}

.th {
  padding: 0.5rem;
  font-size: 13px;
  font-weight: 500;
  word-break: break-all;
}