.container {
  
}
.chip {  
  position: relative;
  padding: 5px 12px;
  font-size: 0.8rem;
  border-radius: 30rem;
  background-color: #eee;
  width: fit-content;
  display: block;
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.chip_grammar {
  padding-right: 6px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.deletion {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 10px;
  border-radius: 1.25rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.deletion:hover {
  color: white;
  background-color: red;
}