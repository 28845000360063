@use "sass:map";
@import "styles/variables.scss";

.container {
  height: 100%; // important
  overflow-y: hidden; // important

  display: flex;
  flex-direction: column;
}
.table_wrap {
  background-color: white;
  border-radius: 0.4rem;
  border: 1px solid map.get($--colors, "gray_3");
  height: 100%; // important
  overflow-y: auto; // important
}

.table {
  width: 100%;
  color: #171717;
  table-layout: fixed;
  border: none;
  border-collapse: collapse;

  thead {
    position: sticky;
    top: 0px;
    background-color: #FFFFFF;
    box-shadow: 0 0.25px 0 0 map.get($--colors, "gray_3");

    tr {
      border-bottom: 1px solid map.get($--colors, "gray_3");
    }

    .order {
      padding: 0.125rem;
      font-size: 13px;
      font-weight: 500;
      word-break: break-all;
    }
  }

  tbody tr {
    border-top: 0.5px solid map.get($--colors, "gray_3");
  }
  th:first-child,
  td:first-child {
    width: 3rem;
  }

}

// rowSpan 2 이상인 경우 스크롤 시 border 고정 안 되는 이슈 - https://holystory-dev.com/273
.custom {
  border-collapse: separate;
  border-spacing: 0;

  th, td {
    border-bottom: 0.5px solid map.get($--colors, gray_3);
  }
  
}

.empty-msg {
  margin: 2rem 0;
  width: 100%;
  height: 100%;
  color: #555;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}