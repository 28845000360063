@mixin keyframes($name){
  @keyframes #{$name}{
    @content;
  }
}
@mixin animate($animation,$delay,$duration,$fillMode,$timingFunction){
  animation-name: $animation;
  animation-delay: $delay;
  animation-duration: $duration;
  animation-fill-mode: $fillMode;
  animation-iteration-count: 1;
  animation-timing-function: $timingFunction;
}

@mixin fade-in {
  @include keyframes(show){
    0%{
      opacity: 0;
      pointer-events: none;
    }
    100%{
      opacity: 1;
      pointer-events: auto;
    }
  }
  @include animate(show, 0s, 0.3s, forwards, ease);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none;
  opacity: 0;
}
.is_shown {
  @include fade-in;
}

.normal {
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.dialog {
  @include fade-in;

  &::backdrop {
    @include fade-in;
  }

  padding: 1.5rem;
  border: none;
  border-radius: 1rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}