

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.imageBox {
  width: 100%;
  height: 100%;
  border-radius: .25rem;
  background-color: rgba(0, 0, 0, 0.15);
}

.textBox {
  width: 100%;
  height: .8rem;
  margin-bottom: .45rem;
  border-radius: .125rem;
  background-color: rgba(0, 0, 0, 0.15);
}

.textBox:last-child {
  width: 80%;
  margin-bottom: 0;
}

@keyframes skeleton-loading {
  to {
    opacity: 0.5;
  }
}